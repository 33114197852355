import {useState} from "react";
import {differenceInSeconds, format, parseISO} from 'date-fns';
import _ from 'lodash';

const dateRegex = /^(?:[1-9]\d{3}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1\d|2[0-8])|(?:0[13-9]|1[0-2])-(?:29|30)|(?:0[13578]|1[02])-31)|(?:[1-9]\d(?:0[48]|[2468][048]|[13579][26])|(?:[2468][048]|[13579][26])00)-02-29)$/;

const App = () => {
  const [input, setInput] = useState('');
  const dateEntries = getDateEntries(input);
  const totalSeconds = dateEntries.reduce((partialSum, dateEntry) => partialSum + dateEntry.totalSeconds, 0);
  const totalHours = totalSeconds / 60 / 60;

  console.log({totalHours})

  function getParsedHoursMinutes(timeGroup) {
    const isPm = timeGroup[3].toLowerCase().trim() === 'pm';
    const hours = parseInt(timeGroup[1], 10) + (isPm ? 12 : 0);
    const minutes = parseInt(timeGroup[2], 10);

    return {
      hours,
      minutes,
    }
  }

  function getDateEntries(input) {
    const groups = input.split(/[\r\n]{2,}/).map(g => g.trim()).filter(g => !!g);

    const dtos = groups.map((group, i) => {
      const lines = group.split(/[\r\n]+/).map(g => g.trim()).filter(g => !!g);
      const date = lines.find(line => dateRegex.exec(line));
      const times = lines.map(line => [...line.matchAll(/([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])\s*([AaPp][Mm])/g)])
        .filter(match => !!match && match.length === 2)
        .map(timeGroup => {
          const start = getParsedHoursMinutes(timeGroup[0]);
          const end = getParsedHoursMinutes(timeGroup[1]);
          const isOvernight = end.hours < start.hours;

          const startIso = `1993-08-06T${start.hours.toString().padStart(2, '0')}:${start.minutes.toString().padStart(2, '0')}:00`;
          const endIso = `1993-08-${isOvernight ? '07' : '06'}T${end.hours.toString().padStart(2, '0')}:${end.minutes.toString().padStart(2, '0')}:00`;
          const totalSeconds = differenceInSeconds(parseISO(endIso), parseISO(startIso));
          return {
            start: startIso,
            end: endIso,
            totalSeconds: totalSeconds,
            totalMinutes: totalSeconds / 60 / 60,
          };
        });

      return {
        date: date || `Missing Date ${i + 1}`,
        times
      };
    });

    const dateGroups = _.groupBy(dtos, d => d.date);

    const groupedMap = _.map(dateGroups, (dateGroup, date) => {
      const times = dateGroup.flatMap(dg => dg.times);
      const seconds = times.map(time => time.totalSeconds);

      const totalSeconds = seconds.reduce((partialSum, a) => partialSum + a, 0);
      const totalHours = totalSeconds / 60 / 60;

      return {
        date: date,
        times: times,
        totalSeconds,
        totalHours
      };
    });

    return _.sortBy(groupedMap, g => g.date)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', flex: 1, overflow: 'hidden'}}>
      <div style={{ flex: 1, padding: 10  }}>
        <textarea className="input form-control" value={input} onChange={(e) => setInput(e.target.value)} />
      </div>
      <div style={{ flex: 1, padding: 10, overflow: 'auto'  }}>
        <h6>Total Hours: {totalHours.toFixed(2)}</h6>

        <table className="table table-striped table-hover">
          <thead>
          <tr>
            <th>Date</th>
            <th>Developer</th>
            <th>Hours</th>
            <th>Billable Hours</th>
          </tr>
          </thead>

          <tbody>
          {
            dateEntries.map(({ date, times, totalHours }) => {
              return (
                <tr key={date}>
                  <td>{date}</td>
                  <td>Scott</td>
                  <td>{totalHours.toFixed(2)}</td>
                  <td>{' '}</td>
                </tr>
              );
            })
          }
          </tbody>
        </table>

        <div>
          {
            dateEntries.map(({ date, totalHours, times }) => {
              // 7:04pm-8:05pm- 1 hour
              // 9:18pm-10:04pm- .77 hours
              return (
                <div key={date}>
                  {date && <div><strong>{date}</strong></div>}
                  {
                    _.map(times, ({ start, end, totalMinutes }, i) => (
                      <div key={i}>
                        {format(parseISO(start), 'h:mmaaa')}-{format(parseISO(end), 'h:mmaaa')}- {totalMinutes.toFixed(2)} hours
                      </div>
                    ))
                  }
                  <br />
                </div>
              );
            })
          }
        </div>
      </div>
    </div>
  );
};

export default App;
